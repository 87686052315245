<template>
	<div class="garden-detail-page" v-show="pageLoading">
		<div class="form-wrapper">
			<a-form-model
				:model="form"
				:rules="policyRules"
				ref="parkForm"
				:label-col="labelCol"
				:wrapper-col="wrapperCol"
			>
				<!--<div class="block-tl"> 匹配信息 </div>
				<a-row>
					<a-col :span="8">
						<a-form-model-item ref="" label="注册时间" prop="">
							<a-range-picker v-model="dateValue" @change="dateChange" />
						</a-form-model-item>
					</a-col>
				<a-col :span="8">
					<a-form-model-item ref="" label="自主知识产权数(件)" prop="">
						<a-select class="a-select" placeholder="请选择" mode="multiple" v-model="form.property_rights">
							<a-select-option :value="item.id" v-for="(item, index) in rightList" :key="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col> 
				</a-row>
				<a-row>
					<a-col :span="8">
						<a-form-model-item  :labelCol="{ span: 10 }" :wrapperCol="{ span: 14 }" ref="" label="产品/服务技术领域" prop="">
							<a-select class="a-select" placeholder="请选择" v-model="form.main_products">
								<a-select-option v-for="(item, index) in proSelect" :key="item.id">{{ item.name }}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col> 
					<a-col :span="8">
						<a-form-model-item  :labelCol="{ span: 8 }" :wrapperCol="{ span: 16}" ref="" label="国家高新技术企业" prop="">
							<a-switch :checked="form.is_high == 1" @change="highChange" />
						</a-form-model-item>
					</a-col>
				</a-row>
				-->
				<div class="block-tl">基础信息</div>
				<a-row>
					<a-col :span="8">
						<a-form-model-item ref label="类型" prop="policy_type_id">
							<a-select class="a-select" placeholder="请选择" v-model="form.policy_type_id">
								<a-select-option
									v-for="(item, index) in typeList"
									:key="item.policy_type_id"
								>{{ item.policy_type_name }}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :span="8">
						<a-form-model-item ref label="名称" prop="policy_name">
							<a-input v-model="form.policy_name" placeholder="请输入名称" />
						</a-form-model-item>
					</a-col>
					<a-col :span="8">
						<a-form-model-item ref label="来源" prop="source">
							<a-input v-model="form.source" placeholder="请输入来源" />
						</a-form-model-item>
					</a-col>
					<a-col :span="8">
						<a-form-model-item ref="addr" label="地区">
							<a-cascader
								v-model="form.addrValue"
								class="cascader"
								:field-names="{ label: 'name', value: 'name', children: 'districts' }"
								:load-data="loadSsq"
								:changeOnSelect="true"
								:options="ssqData"
								placeholder="请选择省市区"
								@change="ssqChange"
							/>
						</a-form-model-item>
					</a-col>
					<a-col :span="8">
						<a-form-model-item ref label="浏览量" prop="input_count">
							<a-input type="number" v-model="form.input_count" placeholder="请输入" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<!-- <a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{ span: 22 }" ref="" label="标签" prop="policy_tag_id">
					<a-select mode="multiple" class="a-select" placeholder="请选择" v-model="form.policy_tag_list">
						<a-select-option v-for="(item, index) in tagList" :key="item.policy_tag_id">{{ item.policy_tag_name }}</a-select-option>
					</a-select>
				</a-form-model-item>-->
				<a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{ span: 22 }" ref label="是否发布" prop>
					<a-switch :checked="form.is_show == 1" @change="showChange" />
				</a-form-model-item>
				<a-form-model-item
					:labelCol="{ span: 2 }"
					:wrapperCol="{ span: 22 }"
					ref="header_img"
					label="政策展示图"
					prop="header_img"
				>
					<a-upload
						:action="$domain + '/api/resource/uploadImg'"
						list-type="picture-card"
						@change="handleHead"
						:show-upload-list="false"
					>
						<img class="place-img" :src="$imglink + form.header_img" v-if="form.header_img" />
						<div v-else>
							<a-icon type="plus" />
							<div class="ant-upload-text">上传图片</div>
						</div>
					</a-upload>
				</a-form-model-item>
				<a-form-model-item
					:labelCol="{ span: 2 }"
					:wrapperCol="{ span: 22 }"
					ref="park_introduce"
					label="政策详情"
					prop="content"
				>
					<!-- <div id="editorElem" style="text-align:left;display: none;"></div> -->

					<!-- <ckeditor id="editor" :editor="editor1" @ready="onReady" v-model="form.content" :config="editorConfig"></ckeditor> -->

					<div id="editor1-toolbar-container"></div>
					<div id="editor1"></div>
				</a-form-model-item>
				<a-form-model-item
					:labelCol="{ span: 2 }"
					:wrapperCol="{ span: 22 }"
					ref="park_policy"
					label="政策简读"
				>
					<!-- <div id="editorElem2" style="text-align:left;"></div> -->
					<div id="editor2-toolbar-container"></div>
					<div id="editor2"></div>
				</a-form-model-item>
				<a-form-model-item :labelCol="{ span: 2 }" :wrapperCol="{ span: 6 }" label="选择文件">
					<a-upload
						:action="$domain + '/api/resource/uploadAppendix'"
						@change="handlefile"
						:fileList="fileTypeList"
						class="file-upload"
						:show-upload-list="true"
					>
						<a-button>
							<a-icon type="upload" />选择文件
						</a-button>
					</a-upload>
					<div class="gray-tips">支持word、PPT、pdf、Excel</div>
				</a-form-model-item>
			</a-form-model>

			<div class="sub-form-btn-box">
				<a-button :loading="subing" type="primary" @click="subForm">提交</a-button>
			</div>
		</div>
		<div class="loading-mask" v-show="subing">
			<a-spin>
				<a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
			</a-spin>
		</div>
		<div class="upload-box" v-show="uploading">
			<a-spin tip="上传中..."></a-spin>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import { ssqdata } from '../../utils/ssqdata.js'
import CKEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh-cn'
import MyUploadAdapter from "../../utils/uploader";
import { toolbar, fontSize } from '../../utils/toolbar'
export default {
	data() {
		let addrValidator = (rule, value, callback) => {
			if (value.length <= 0) {
				callback(new Error('请补全地域信息'));
			} else {
				callback();
			}
		};

		let countValidator = (rule, value, callback) => {
			console.log(value)
			if (value.length != '' && isNaN(value)) {
				callback(new Error('浏览量只能请填写数字'));
			} else {
				callback();
			}
		};

		return {
			editor1: null,
			editor2: null,
			editor1Data: '',
			editor2Data: '',
			pageLoading: false,
			subing: false,
			labelCol: { span: 6 },
			wrapperCol: { span: 18 },
			policyRules: {
				policy_type_id: [{ required: true, message: '请选择类型', trigger: 'change' }],
				policy_name: [{ required: true, message: '请填写政策名字', trigger: 'blur' }],
				source: [{ required: true, message: '请填写来源', trigger: 'blur' }],
				policy_tag_list: [{ required: true, message: '请选择相关政策', trigger: 'change' }],
				header_img: [{ required: true, message: '请选择政策展示图片', trigger: 'change' }],
				exhibition_img_list: [{ required: true, message: '请选择园区图片', trigger: 'change' }],
				content: [{ required: true, message: '请输入政策详情', trigger: 'change' }],
				input_count: [{
					validator: countValidator, trigger: 'change'
				}]
			},
			proSelect: [],
			typeList: [],
			rightList: [],
			tagList: [],
			dateValue: [],
			addrCode: [],
			addrValue: [],
			ssqData: ssqdata,
			allSsqData: ssqdata,
			curParent: [],
			fileTypeList: [],
			id: '',
			form: {
				addrValue: [],
				policy_name: '', //政策名字
				begin_re_time_str: '', //开始时间
				end_re_time_str: '', //开始时间
				main_products: undefined, //主营领域，
				property_rights: [], //自主知识产权数(件)
				is_high: -1, //是否是高新技术产业
				policy_type_id: undefined,
				source: '',//来源
				header_img: '',
				pro: '',
				pro_code: '',
				city: '',
				city_code: '',
				area: '',
				area_code: '',
				input_count: '', //浏览量
				policy_tag_list: [], //标签
				is_show: -1, //是否发布
				simple_re: '', //政策简牍
				content: '', // 详情内容
				appendix_list: '', //政策简牍
			},
			uploading: false,
			editorContent: '',
			editorConfig: {
				toolbar: ['heading', 'fontSize', 'highlight', 'highlight:yellowMarker', 'highlight:greenMarker', 'highlight:pinkMarker', 'highlight:blueMarker', 'fontFamily', 'alignment', 'imageUpload', 'bold', 'italic', 'underline', 'imageStyle:full', 'imageStyle:alignLeft', 'imageStyle:alignRight', 'link', 'undo', 'redo'],

				highlight: {
					options: [
						{ model: 'blackPen', class: 'pen-black', title: '黑色', color: 'var(--ck-highlight-pen-black)', type: 'pen' },
						{ model: 'redPenPen', class: 'pen-red', title: '红色', color: 'var(--ck-highlight-pen-red)', type: 'pen' },
					]
				}
			},
			ckfinder: {
				uploadUrl: `${this.domain}/ckeditor/upload`,
				// 后端处理上传逻辑返回json数据,包括uploaded 上传的字节数 和url两个字段
			},
		}
	},
	computed: {},
	watch: {
		editor1Data(val) {
			this.form.content = val
		},
		editor2Data(val) {
			this.form.simple_re = val
		}
	},
	async created() {
		let proRes = await this.getMainProductsSelect()
		if (proRes.code == 0) {
			this.proSelect = proRes.data
		}
		let typeRes = await this.getPolicyTypeSelect()
		if (typeRes.code == 0) {
			this.typeList = typeRes.data
		}
		let rightRes = await this.getPropertyRightsSelect()
		if (rightRes.code == 0) {
			this.rightList = rightRes.data
		}
		let tagRes = await this.getPolicyTagSelect()
		if (tagRes.code == 0) {
			this.tagList = tagRes.data
		}
		if (this.$route.query.id) {
			this.id = this.$route.query.id
			this.getPolicy()
		}
	},
	mounted() {
		let t = this
		t.initCKEditor('editor1')
		t.initCKEditor('editor2')
		setTimeout(() => { t.pageLoading = true }, 400)
	},
	methods: {
		myUploadImagePlugin(ele) {
			let t = this
			t[ele].plugins.get("FileRepository").createUploadAdapter = loader => {
				return new MyUploadAdapter(loader);
			};
			t[ele].model.document.on('change:data', function () {
				t[ele + 'Data'] = t[ele].getData()
			});
		},
		initCKEditor(ele) {
			let t = this
			CKEditor.create(document.querySelector('#' + ele), {
				toolbar: toolbar,
				fontSize: fontSize,
				language: 'zh-cn',
				ckfinder: {
					uploadUrl: '/admin/Upload/uploadUrl'
					//后端处理上传逻辑返回json数据,包括uploaded(选项true/false)和url两个字段
				},
			}).then(editor => {
				const toolbarContainer = document.querySelector('#' + ele + '-toolbar-container');
				console.log(toolbarContainer)
				toolbarContainer.appendChild(editor.ui.view.toolbar.element);
				this[ele] = editor //将编辑器保存起来，用来随时获取编辑器中的内容等，执行一些操作
				t.myUploadImagePlugin(ele)
				console.log(Array.from(this[ele].ui.componentFactory.names()))
			}).catch(error => {
				console.error(error);
			});
		},
		onReady() {

		},
		getPolicy() {
			let t = this
			t.$post('PolicyInfo', { policy_id: t.id }).then(res => {
				let { code, data, msg } = res
				if (code == 0) {
					for (let i = 0; i < data.property_rights.length; i++) {
						data.property_rights[i] = parseFloat(data.property_rights)
					}
					data.main_products = data.main_products === '' ? undefined : parseFloat(data.main_products)
					this.form = data
					this.dateValue = [data.begin_re_time_str, data.end_re_time_str]
					let li = 1;
					let appendix_list = JSON.parse(data.appendix_list)
					this.fileTypeList = appendix_list.map(item => {
						let a = {
							uid: -li,
							name: item.name,
							status: 'done',
							url: this.$imglink + item.resource_id,
							response: {
								data: {
									resource_id: item.resource_id,
									url: this.$imglink + item.resource_id
								}
							}
						}
						li++
						return a
					})
					console.log('wenjian', this.form)
					this.addrCode = [this.form.pro_code, this.form.city_code, this.form.area_code]
					this.form.addrValue = [this.form.pro, this.form.city, this.form.area]
					this.curParent = ssqdata
					this.initLoad(0)
					this.editor1.setData(this.form.content)
					this.editor2.setData(this.form.simple_re)
				} else {
					t.$message.error(msg, 1.5)
				}

			}).catch(err => {
				this.pageLoading = true
			})
		},
		dateChange(e, b) {
			console.log(e, b)
			this.form.begin_re_time_str = b[0]
			this.form.end_re_time_str = b[1]
		},
		getMainProductsSelect() {
			return this.$post('mainProductsSelect')
		},
		getPolicyTypeSelect() {
			return this.$post('PolicyTypeSelect')
		},
		getPropertyRightsSelect() {
			return this.$post('propertyRightsSelect')
		},
		getPolicyTagSelect() {
			return this.$post('PolicyTagSelect')
		},
		highChange(e) {
			this.form.is_high = e ? 1 : -1
		},
		showChange(e) {
			this.form.is_show = e ? 1 : -1
		},
		handleHead(res) {
			console.log(res);
			if (res.file.status == 'done') {
				this.form.header_img = res.file.response.data.resource_id;
				console.log(this.form.header_img);
			}
		},
		handlefile(e) {
			console.log(e)

			this.fileTypeList = e.fileList
		},
		initLoad(ind) {
			console.log(13123123131231231231231)
			let code = this.addrCode[ind]
			for (let i = 0; i < this.curParent.length; i++) {
				let item = this.curParent[i]
				if (code == item.adcode) {
					const targetOption = item;
					targetOption.loading = true;
					let subdistrict = targetOption.level == 'province' ? 1 : targetOption.level == 'city' ? 2 : targetOption.level == 'district' ? 3 : 4;
					axios.get('https://restapi.amap.com/v3/config/district', {
						params: {
							keywords: targetOption.adcode,
							subdistrict: subdistrict,
							key: 'ce7404ac71c28a7893cae3ccc5517da0'
						}
					}).then(res => {
						for (let i = 0; i < res.data.districts[0].districts.length; i++) {
							let item = res.data.districts[0].districts[i];
							delete item.districts;
							item.isLeaf = item.level == 'district' || res.data.districts[0].adcode == 810000 || res.data.districts[0].adcode == 820000;
						}
						targetOption.districts = res.data.districts[0].districts;
						this.ssqData = [...this.ssqData];
						targetOption.loading = false;
						this.curParent = res.data.districts[0].districts;
						console.log(ind + 2)
						if (ind + 2 != this.addrCode.length) {
							this.initLoad(ind + 1)
						}
					}).catch(err => {
						console.log(err);
					});
					break
				}
			}
		},
		loadSsq(selectedOptions) {
			console.log(selectedOptions)
			const targetOption = selectedOptions[selectedOptions.length - 1];
			targetOption.loading = true;
			// load options lazily
			let subdistrict = targetOption.level == 'province' ? 1 : targetOption.level == 'city' ? 2 : targetOption.level == 'district' ? 3 : 4;
			axios
				.get('https://restapi.amap.com/v3/config/district', {
					params: {
						keywords: targetOption.adcode,
						subdistrict: subdistrict,
						key: 'ce7404ac71c28a7893cae3ccc5517da0'
					}
				})
				.then(res => {
					let districts = res.data.districts[0].districts;
					for (let i = 0; i < districts.length; i++) {
						let item = districts[i];
						delete item.districts;
						item.isLeaf = item.level == 'district' || res.data.districts[0].adcode == 810000 || res.data.districts[0].adcode == 820000;
					}
					targetOption.districts = districts;
					targetOption.loading = false;
					this.ssqData = [...this.ssqData];
				})
				.catch(err => {
					console.log(err);
				});
		},
		ssqChange(e, opt) {
			this.form.pro = e[0] || '';
			this.form.city = e[1] || '';
			this.form.area = e[2] || '';
			this.form.pro_code = opt && opt[0] ? opt[0].adcode : '';
			this.form.city_code = opt && opt[1] ? opt[1].adcode : '';
			this.form.area_code = opt && opt[2] ? opt[2].adcode : '';
			console.log(this.form.pro)
		},
		subForm() {
			let t = this
			t.$refs.parkForm.validate(valid => {
				if (valid) {
					t.subing = true;
					let params = JSON.parse(JSON.stringify(this.form))
					let appendix_list = this.fileTypeList.map(item => {
						let obj = {
							name: item.name,
							resource_id: item.response.data.resource_id
						}
						return obj
					})
					params.appendix_list = JSON.stringify(appendix_list)
					let url = ''
					if (t.id) {
						url = 'PolicyEdit'
					} else {
						url = 'PolicyAdd'
					}
					t.$post(url, params).then(res => {
						t.subing = false;
						let { code, data, msg } = res
						if (code == 0) {
							t.$message.success(msg, 1.5)
							t.$router.history.go(-1)
						} else {
							t.$message.error(msg, 1.5)
						}
					}).catch(err => {
						t.subing = false;
					})
				}
			})
		}
	}
}
</script>

<style lang="less">
.block-tl {
	line-height: 44px;
	font-size: 16px;
	border-bottom: 1px solid #dedede;
	margin-bottom: 20px;
}
.gray-tips {
	color: #999;
}
.file-upload {
	.ant-upload-list-item {
		height: 50px;
		.anticon-paper-clip {
			top: 17px;
			left: 15px;
		}
		&:hover {
			.ant-upload-list-item-info {
				background: none;
			}
			.ant-upload-list-item-name {
				background-color: #e6f7ff;
			}
		}
	}
	.ant-upload-list-item-name {
		width: 85%;
		background: #f8f8f8;
		border-radius: 42px;
		height: 50px;
		line-height: 50px;
		color: #1890ff;
		padding: 0 30px;
	}
	.ant-upload-list-item-card-actions {
		top: 15px;
	}
}
.upload-box {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 999999999999;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(255, 255, 255, 0.5);
}
.ck-content {
	line-height: normal;
	p {
		margin-bottom: 0;
	}
}
</style>
